import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { ArrowLeft, ArrowRight } from "react-feather";
import { Container } from 'components/library';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { ArticleCard } from "components/common/article-card";
import { Newsletter } from "components/common/newsletter";
import { CtaBlock } from "components/common/cta-block";
import { getCanonicalPageId } from "notion-utils";

type P = {
    pageContext: {
        articles: any[]
    }
}

const CaseStudiesNWhitePapers = ({ pageContext: { articles } }: P) => {
    const { t } = useTranslation()
    const caseStudies = articles[0];
    const whitePapers = articles[1];

    return (
        <main className='-mt-28 sm:-mt-36'>
            <section className='relative text-white flex flex-col gap-8 pb-12 sm:pb-16 pt-40 sm:pt-60 items-center text-center px-8 bg-hero-bg-flat-mobile sm:bg-hero-bg-flat bg-no-repeat bg-cover'>
                <span className='text-4xl sm:text-5xl md:text-6xl max-w-4xl'>
                    {t('case-studies-n-white-papers-page.heading')}
                </span>
                <p className='max-w-4xl text-lg sm:text-xl'>
                    {t('case-studies-n-white-papers-page.desc')}
                </p>
            </section>

            <Container className='flex flex-col gap-20 py-24'>
                <section className='flex flex-col gap-12'>
                    <div className='flex flex-col gap-8'>
                        <div className='flex flex-col gap-2'>
                            <h2 className='text-3xl text-theme-blue'>
                                {t('common.words.case-studies')}
                            </h2>
                            <span className='bg-gray-200 h-[2px]'></span>
                        </div>
                        <div className='flex h-full'>
                            <ArticlesCarousel articles={caseStudies} baseLink="/case-study" />
                        </div>
                    </div>

                    <div className='flex flex-col gap-8'>
                        <div className='flex flex-col gap-2'>
                            <h2 className='text-3xl text-theme-blue'>
                                {t('common.words.white-papers')}
                            </h2>
                            <span className='bg-gray-200 h-[2px]'></span>
                        </div>
                        <div className='flex h-full'>
                            <ArticlesCarousel articles={whitePapers} baseLink="/white-paper" />
                        </div>
                    </div>
                </section>

                <Newsletter />
            </Container>

            {/* ONLY FOR GOOGLE INDEXING */}
            <Link to="/download-document/verify" className="invisible">
                Document Verification
            </Link>

            <CtaBlock />
        </main>
    )
};

export default CaseStudiesNWhitePapers

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const getChunks = (array: any[], size: number) => {
    let result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};

export const ArticlesCarousel = ({
    articles,
    baseLink
}: any) => {
    const [slide, setSlide] = useState(0);

    const getLink = (article: any) => getCanonicalPageId(article.id, article, { uuid: false });

    const articleChunks = getChunks(
        articles.map((article: any) => {
            return <ArticleCard
                key={article.id}
                className='max-w-full h-full md:max-w-[384px]'
                article={article}
                title={article.title}
                excerpt={article.excerpt}
                type="default"
                link={`${baseLink}/${getLink(article)}`}
            />
        }),
        3
    );

    return (
        <section className="flex flex-col gap-2 relative md:-mb-16">
            <div>
                {/* LARGE SCREENS AND ABOVE */}
                {articleChunks.length > 1 && (
                    <button
                        disabled={slide === 0}
                        className="bg-white drop-shadow-md h-12 w-12 rounded-full flex justify-center items-center disabled:text-black/20 top-1/2 left-[-4vw] transform -translate-y-1/2 hidden lg:flex lg:absolute"
                        onClick={() => setSlide((prev) => prev - 1)}
                    >
                        <ArrowLeft />
                    </button>
                )}
                {articleChunks.length > 1 && (
                    <button
                        disabled={slide === articleChunks.length - 1}
                        className="bg-white drop-shadow-md h-12 w-12 rounded-full flex justify-center items-center disabled:text-black/20 top-1/2 right-[-4vw] transform -translate-y-1/2 hidden lg:flex lg:absolute"
                        onClick={() => setSlide((prev) => prev + 1)}
                    >
                        <ArrowRight />
                    </button>
                )}


                <Carousel
                    className="hidden lg:block"
                    showStatus={false}
                    showArrows={false}
                    selectedItem={slide}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        return (
                            <button
                                className={`${isSelected ? 'bg-orange-400' : 'bg-neutral-200'} w-3 h-3 rounded-lg m-1`}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                aria-label={`${label} ${index + 1}`}
                            >
                            </button>
                        );
                    }}
                >
                    {articleChunks.map((chunk) => (
                        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 h-full pb-16">
                            {chunk.map((article) => article)}
                        </div>
                    ))}
                </Carousel>

                {/* MEDIUM SCREENS AND BELOW */}
                <Carousel
                    className="block lg:hidden"
                    showStatus={false}
                    showArrows={false}
                    selectedItem={slide}
                    showIndicators={false}
                >
                    {articles.map((article: any) => {
                        return <ArticleCard
                            key={article.id}
                            className='max-w-full h-full md:max-w-[384px]'
                            article={article}
                            title={article.title}
                            excerpt={article.excerpt}
                            type="default"
                            link={`${baseLink}/${getLink(article)}`}
                        />
                    })}
                </Carousel>
                <div className="flex gap-4 absolute right-6 -bottom-0">
                    <button
                        disabled={slide === 0}
                        className="bg-white drop-shadow-md h-12 w-12 rounded-full flex justify-center items-center disabled:text-black/20 static lg:hidden"
                        onClick={() => setSlide((prev) => prev - 1)}
                    >
                        <ArrowLeft />
                    </button>
                    <button
                        disabled={slide === articles.length - 1}
                        className="bg-white drop-shadow-md h-12 w-12 rounded-full flex justify-center items-center disabled:text-black/20 static lg:hidden"
                        onClick={() => setSlide((prev) => prev + 1)}
                    >
                        <ArrowRight />
                    </button>
                </div>
            </div>
        </section>
    );
};

